<template>
  <!-- <layout class="home"> -->
  <!-- 顶部banner -->
  <div>
    <div class="home-wrapper-banner">
      <!-- <div class="page-head-seize"></div> -->
      <!-- <div class="container" style="margin-left: 8%;"> -->
      <!-- <h1 class="title">100%国产数字化电气设计云平台！</h1> -->
      <!-- <h3 class="desc">永久免费！<br/></h3> -->
      <!-- <h3 class="desc-date">(截止至2022.12.31)</h3> -->
      <!-- <a href="https://eic.yldatacloud.com">  <el-button class="banner-btn" round>免费体验</el-button></a> -->
      <!-- </div> -->
      <!-- <div class="col-md-12">
        <div class="" style="margin-left: 8%;width: 55%"> -->
      <!-- <img :src="imgArr[index]" alt="" style="z-index: -1; overflow: hidden;" /> -->

      <!-- <h1 class="title">100%国产数字化电气设计云平台！</h1>
          <a href="https://eic.yldatacloud.com"> <el-button class="banner-btn" round>免费体验</el-button></a>
        </div>
      </div> -->
      <!-- <el-carousel indicator-position="outside" style="width:100% ;height:960px"> -->
      <el-carousel interval="5000" arrow="always" style="width:100% ;height:960px" loop="false">
        <el-carousel-item style="width: 100%; height: 960px;" v-for="(item,index) in imgArray" :key="item">
          <img :src="item" class="rightImg">
          <h1 :class="{title:index===0}">100%国产数字化电气设计云平台！</h1>
          <a href="https://eic.yldatacloud.com">
            <el-button :class="{banner_btn:index===0,banner_btn2:index===1}" round>免费体验</el-button>
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 介绍 -->
    <div class="container">
      <Libraries></Libraries>
      <DesignTool></DesignTool>
      <Engineering></Engineering>

    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Layout from "@/components/Layout.vue";
  import Libraries from "@/components/Home/Libraries.vue";
  import DesignTool from "@/components/Home/DesignTool.vue";
  import PlayVideo from "@/components/Home/PlayVideo.vue";
  import ProductBox from "@/components/Home/ProductBox.vue";
  import Engineering from "@/components/Home/Engineering.vue";


  export default {
    name: "HomeView",
    components: {
      Layout,
      Libraries,
      DesignTool,
      PlayVideo,
      ProductBox,
      Engineering
    },
    data() {
      return {
        imgArray: [
          require('../assets/imgs/banner1.png'),
          require('../assets/imgs/banner2.png')
        ],

      }
    }

  };
</script>

<style lang="scss" scoped>
  .one {
    margin: 60px;

  }


  .home-wrapper-banner {

    /* background: #abd8eb url("../assets/imgs/1705645886733.png") no-repeat ; */
    /* background-size: 100vw; */
    /* background-position: calc(100% + 300px) calc(100% + 90px);  */
    width: 100%;
    height: 980px;
    /* background-repeat: no-repeat;
    background-image: url("../assets/imgs/banner1.png");
    animation: frams 15s infinite; */

    min-height: 85vh;
    color: #fff;
    text-align: left;
    position: relative;

    .container1 {
      position: relative;
      z-index: 2;
    }

    .rightImg {
      /* margin-top: 60px; */
      width: 100%;
      height: 960px;
      overflow: hidden;
      /* position: relative; */
    }

    /* 调整走马灯箭头-左箭头 */
    ::v-deep .el-carousel__arrow--left {
      top: 460px;
      left: 23px;
      font-size: 35px;
    }

    /* 右箭头 */
    ::v-deep .el-carousel__arrow--right {
      top: 460px;
      right: 23px;
      font-size: 35px;
    }

    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
      background-color: #d3dce6;
    }





    /* @keyframes frams {
      0% {
        background-image: url("../assets/imgs/banner1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat
      }

      50% {
        background-image: url("../assets/imgs/banner2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat
      }
    } */

    .banner_btn {
      padding: 17px 47px;
      border-radius: 40px;
      font-size: 1.8vw;
      margin-top: 160px;
      background-color: #32516e;
      color: white;
      z-index: 1;
      position: absolute;
      top: 45%;
      left: 14%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .banner_btn2 {
      padding: 17px 47px;
      border-radius: 40px;
      font-size: 1.8vw;
      margin-top: 160px;
      margin-left: 260px;
      background-color: #32516e;
      color: white;
      position: absolute;
      top: 65%;
      left: 37%;
      transform: translate(-50%, -50%);
      z-index: 1;

    }

    .banner-btn:hover {
      padding: 17px 47px;
      border-radius: 40px;
      font-size: 1.8vw;
      margin-top: 160px;
      background-color: #5887b4;
      color: white;
    }

    .title {
      font-size: 3.7vw;
      /* padding: 40px 0; */
      padding-top: 5vw;
      padding-bottom: 1vw;
      font-weight: bold;
      color: #2e5375;
      position: absolute;
      top: 30%;
      left: 36%;
      transform: translate(-50%, -50%);
      z-index: 1;

    }

    .desc {
      /* font-weight: normal; */
      font-size: 2.5vw;
      padding: 10px 0;
      color: black;

    }

    .desc-date {
      /* font-weight: normal; */
      font-size: 12px;
      padding: 10px 0;
      color: black;

    }
  }
</style>