<template>
  <div class="home-container learn-video play-video-cn">
    <div class="container">
      <div class="video-bg">
        <div class="play-all-button play-footprint-button">
          <a href="javascript:;" @click="handleShow">
            <i></i>
            <span value="Footprint-Manager">封装管理器</span>
          </a>
        </div>
        <div class="play-all-button play-theme-button">
          <a href="javascript:;" @click="handleShow">
            <i></i>
            <span value="Schematic-Theme">原理图主题</span>
          </a>
        </div>
        <div class="play-all-button play-auto-button">
          <a href="javascript:;" @click="handleShow">
            <i></i>
            <span value="Auto-Router">自动布线</span>
          </a>
        </div>
        <div class="play-all-button play-design-button">
          <a href="javascript:;" @click="handleShow">
            <i></i>
            <span value="Design-Manager">设计管理器</span>
          </a>
        </div>
        <div class="play-all-button play-3d-button">
          <a href="javascript:;" @click="handleShow">
            <i></i>
            <span value="3D-View">3D预览</span>
          </a>
        </div>
        <div class="play-all-button play-wizard-button">
          <a href="javascript:;" @click="handleShow">
            <i></i>
            <span value="SchematicLib-Wizard">原理图库向导</span>
          </a>
        </div>
        <div class="play-all-button play-check-button">
          <a href="javascript:;" @click="handleShow">
            <i></i>
            <span value="Dimension-Check">尺寸检查</span>
          </a>
        </div>
        <div class="play-all-button play-version-button">
          <a href="javascript:;" @click="handleShow">
            <i></i>
            <span value="Version-Control">版本管理</span>
          </a>
        </div>
        <div class="play-all-button play-team-button">
          <a href="javascript:;" @click="handleShow">
            <i></i>
            <span value="Team-Collaboration">团队协作</span>
          </a>
        </div>
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :show-close="false"
    >
      <div class="video-box">
        <div class="video-warp">
          <iframe
            src="//player.bilibili.com/player.html?aid=521194664&amp;bvid=BV1cM411U7Jk&amp;cid=921010987&amp;page=1"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe>
          <span @click="handleClose">x</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleShow(done) {
      this.dialogVisible = true;
    },
    handleClose(done) {
      // done && done();
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-box{
  iframe{
    width: 100%;
    height: 30vw
  }
}
.video-box span {
  width: 32px;
  height: 32px;
  font-size: 28px;
  color: #fff;
  position: absolute;
  top: -7%;
  left: 102%;
  cursor: pointer;
}
.learn-video {
  ::v-deep .el-dialog__header,
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
.learn-video .video-bg {
  background: url(../../assets/images/v-normal.jpg) no-repeat;
  height: 651px;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
}

.learn-video .video-bg .play-all-button {
  position: absolute;
  width: 240px;
  height: 62px;
  line-height: 62px;
  background: #000;
  border-radius: 31px;
  opacity: 0.8;
}

.learn-video .video-bg .play-all-button a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.learn-video .video-bg .play-all-button a i {
  display: block;
  width: 42px;
  height: 42px;
  background: url(../../assets/images/vedio-play.png) no-repeat;
  background-size: 100% 100%;
  margin: 10px;
}

.learn-video .video-bg .play-all-button a span {
  display: block;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #fff;
}

.learn-video .video-bg .play-all-button:hover {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.learn-video .video-bg .play-footprint-button {
  top: 234px;
  left: 130px;
}

.learn-video .video-bg .play-theme-button {
  top: 182px;
  left: 435px;
}

.learn-video .video-bg .play-auto-button {
  top: 475px;
  left: 443px;
}

.learn-video .video-bg .play-design-button {
  top: 345px;
  left: 696px;
}

.learn-video .video-bg .play-3d-button {
  top: 224px;
  left: 848px;
}

.learn-video .video-bg .play-wizard-button {
  top: 464px;
  left: 804px;
}

.learn-video .video-bg .play-check-button {
  top: 277px;
  left: 1104px;
}

.learn-video .video-bg .play-version-button {
  top: 390px;
  left: 1214px;
}

.learn-video .video-bg .play-team-button {
  top: 513px;
  left: 1238px;
}

.learn-video .en-video-bg {
  height: 651px;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
}
</style>