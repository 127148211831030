<template>
  <div class="page-footer-wrapper">
    <div class="container">
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="grid-content bg-purple jlc-footer">
            <div class="footer-codeimg">
              <img src="../assets/images/logo_12.png" alt="" style="height:80px;">
              <div style="display: flex;font-size: 36px; align-items: center;padding-left: 10px;">全生数字</div>
            </div>
          </div>
        </el-col>
        <el-col :span="12" :offset="0" class="">
          <div class="flex-1 flex justify-around jlc-footer">
            <div>
              <h4 class="title">政策条款</h4>
              <div class="link-list">
                <a @click="gotoabout('4')" title="用户协议"
                  class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">用户协议 </a>
              </div>
            </div>
            <div>
              <h4 class="title">关于我们</h4>
              <div class="link-list">
                <a @click="gotoabout('0')" title="公司介绍"
                  class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">公司介绍 </a>
                <a @click="gotoabout('1')" title="项目案例"
                  class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">项目案例 </a>
                <a @click="gotoabout('2')" title="新闻动态"
                  class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">新闻动态 </a>
                <a @click="gotoabout('3')" title="联系我们"
                  class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">联系我们 </a>
              </div>
            </div>
            <div>
              <h4 class="title">技术支持</h4>
              <div class="link-list">
                <a @click="gotoabout('5')" title="产品教程"
                  class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">产品教程 </a>
                <a @click="gotoabout('6')" title="更新内容"
                  class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">更新内容 </a>
                <a @click="gotoabout('7')" title="常见问题"
                  class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">常见问题 </a>
                <a @click="gotoabout('8')" title="问题反馈"
                  class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">问题反馈 </a>
              </div>
            </div>
            <div>
              <h4 class="title" style="text-align: left;">联系我们</h4>
              <div class="link-list">
                <a title="电话" class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">
                  <i class="el-icon-phone-outline"></i> 181 2611 8048 </a>
                <a title="邮箱" class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">
                  <i class="el-icon-message"></i> support@yldatacloud.com </a>
                <a title="地址" class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18">
                  <i class="el-icon-map-location"></i> 广东省广州市海珠区新滘中路44号204 </a>
                <a title="办公时间" class="text-14 text-ccc hover:underline hover:text-ccc mr-40 mb-18 ">
                  <i class="el-icon-time"></i> 周一至周五
                  9:00~18:00 </a>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4" :offset="0">
          <!-- <div style="color: white;margin-top: 50px;font-size: 18px;margin-bottom: 10px;display:flex;"> 关注或联系我们</div> -->
          <div class="flex-1 flex justify-between jlc-footer" style="margin-top: 50px;">
            <div class="footer-codeimg"><img src="../../public/img/icons/support.png" alt="" style="height:90px;">客服咨询
            </div>
            <div class="footer-codeimg"><img src="../../public/img/icons/wechat.jpg" alt="" style="height:90px">微信公众号
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">&nbsp;</div>
        </el-col>
      </el-row>

      <div class="mt-18 mb-28 divider-color h-0 el-divider el-divider--horizontal">
        <!---->
      </div>
      <el-row :gutter="20">
        <el-col :span="4">
          <div class="grid-content bg-purple">&nbsp;</div>
        </el-col>
        <el-col :span="16">
          <div class="footer-copyright">
            <p class="gov"></p>
            <p>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402002736" target="_blank"
                rel="nofollow">粤公网安备 44030402002736号</a>
            </p>
            <p>
              <a href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备2023087020号</a>
            </p>
            <p>&copy; 2023 广东省安成海云科技有限公司 版权所有</p>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 右侧菜单 -->
    <div class="page-right-nav">

      <!-- <el-popover placement="left-start" width="150" trigger="hover" @show="changeShare(true)"
        @hide="changeShare(false)">
        <div style="text-align: center">
          <img src="../assets/images/wechat-fill.svg" alt=""
            style="width: 20px;height:20px;margin-right: 20px;cursor: pointer;">
          <img src="../assets/images/QQ.svg" alt="" style="width: 20px;height:20px;margin-right: 20px;cursor: pointer;">
        </div>
        <div class="share" style="border-radius: 3px 3px 0px 0px;" slot="reference">
          <img src="../../src/assets/imgs/分享.png" alt=""
            style="display: flex; width: 25px;height: 25px;  margin: 5px auto 0px auto;">
          <span style="font-size: 10px;">分享
            <hr style="margin:5px auto 1px auto; width: 60%;" />
          </span><br>

        </div>

      </el-popover> -->

      <!-- <el-popover placement="left" width="290" trigger="hover" @show="changePhone(true)" @hide="changePhone(false)"> -->
      <el-popover placement="left" width="290" trigger="hover" @show="changePhone(true)" @hide="changePhone(false)">
        <div>
          <strong>电话：</strong>181 2611 8048<br />
          <strong>邮箱：</strong>support@yldatacloud.com<br>
          <strong>地址：</strong>广东省广州市海珠区新滘中路44号204<br>
          <strong>办公时间：</strong>周一至周五 9:00~18:00
        </div>
        <!-- <el-button slot="reference"><i class="el-icon-headset"></i></el-button> -->
        <!-- <el-button slot="reference">
          <i v-show="!showPhone" class="el-icon-headset"></i>
          <span v-show="showPhone" class="popover-img">联系<br>我们</span>
        </el-button> -->
        <div class="share" slot="reference" style="border-radius: 3px 3px 0px 0px;">
          <!-- <img src="../../src/assets/imgs/联系我们.png" alt="" style="display: flex; width: 25px;
          height: 25px; margin:0px auto;"> -->
          <img src="../../src/assets/imgs/联系我们.png" alt=""
            style="display: flex; width: 25px;height: 25px;  margin: 5px auto 0px auto;">
          <span style="margin-top: -50px;font-size: 10px;">联系我们</span>
          <hr style="margin:5px auto 1px auto; width: 60%;" />
        </div>
      </el-popover>

      <el-popover placement="left-start" width="150" trigger="hover" @show="changeTechnical(true)"
        @hide="changeTechnical(false)">
        <div style="text-align: center">
          <i class="el-icon-info"></i><strong>客服咨询</strong><br />
          <img src="../../public/img/icons/support.png" style="width: 150px;height:150px;margin-right: 20px;" />
        </div>
        <!-- <el-button slot="reference">
          <i class="el-icon-crop" v-show="!showTechnical"></i>
          <span v-show="showTechnical" class="popover-img">客服<br>咨询</span>
        </el-button> -->
        <div class="share" slot="reference" style="margin-top: 0px">
          <img src="../../src/assets/imgs/客服咨询.png" alt="" style="display: flex; width: 25px;
          height: 25px; margin:0px auto;">
          <span style="font-size: 10px;">客服咨询</span>
          <hr style="margin:5px auto 1px auto; width: 60%;" />
        </div>
      </el-popover>
      <el-popover placement="left-start" width="150" trigger="hover" @show="changeWechat(true)"
        @hide="changeWechat(false)">
        <div style="text-align: center">
          <i class="el-icon-info"></i><strong>微信公众号</strong><br />
          <img src="../../public/img/icons/wechat.jpg" style="width: 150px;height:150px;margin-right: 20px;" />
        </div>
        <!-- <el-button slot="reference">
          <i class="el-icon-bell" v-show="!showWechat"></i>
          <span v-show="showWechat" class="popover-img">微信<br>公众号</span>
        </el-button> -->
        <div class="share" slot="reference" style="margin-top: 0px">
          <img src="../../src/assets/imgs/微信公众号.png" alt="" style="display: flex; width: 25px;
          height: 25px; margin:0px auto;">
          <span style="margin-top: -50px;font-size: 10px;">微信公众号</span>
          <hr style="margin:5px auto 1px auto; width: 60%;" />
        </div>
      </el-popover>
      <!-- <el-button><i class="el-icon-question"></i></el-button> -->
      <div @mouseenter="changeQuestion(true)" @mouseleave="changeQuestion(false)">
        <!-- <el-button slot="reference">
          <i class="el-icon-question" v-show="!showQuestion"></i>
          <span v-show="showQuestion" class="popover-img">问题<br>反馈</span>
        </el-button> -->
        <div class="share" style="height: 46px; border-radius: 0px 0px 3px 3px; margin-top: 0px;cursor: pointer"
          slot="reference" @click="gotoabout('8')">

          <img src="../../src/assets/imgs/问题反馈.png" alt="" style="display: flex; width: 25px;
          height: 25px; margin:-1px auto;">
          <span style="font-size: 10px;">问题反馈
          </span>


        </div>
      </div>
      <a href="#"><el-button style=" width: 50px;height: 50px; font-size: 20px;"><i
            class="el-icon-caret-top"></i></el-button></a>

    </div>
  </div>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
      showShare: false,
      showPhone: false,
      showTechnical: false,
      showWechat: false,
      showQuestion: false

    }
  },
  components: {},
  filters: {},
  methods: {
    changeShare(value) {
      this.showShare = value;
    },
    changePhone(value) {
      this.showPhone = value;
    },
    changeTechnical(value) {
      this.showTechnical = value;
    },
    changeWechat(value) {
      this.showWechat = value;
    },
    changeQuestion(value) {
      console.log(2321);
      this.showQuestion = value;
    },
    gotoabout(index) {
      if (this.$route.path !== '/about') {
        this.$router.push({ path: '/about', query: { index: index } });
      } else {
        this.$store.commit('updateIndex', index);
      }
    },
    // getComponentName() {
    //   this.componentName = "Feedback";
    // },
  },
};
</script>
<style lang="scss" scoped>
.page-footer-wrapper {
  background-color: #252932;

  /* // position: fixed;
    // bottom: 0;
    // left: 0; */
  /* // width: 100%; */
  .flex {
    display: flex !important;
  }

  .justify-between {
    justify-content: space-between !important;
  }

  .justify-around {
    justify-content: space-around !important;
  }

  .items-center {
    align-items: center !important;
  }

  .flex-1 {
    flex: 2 2 2% !important;
  }

  .jlc-footer {
    margin: 50px 0;
  }

  .jlc-footer .title {
    margin-bottom: 24px;
    color: #fff;
    font-size: 16px;
  }

  .footer-codeimg {
    display: flex;
    /* // flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
    color: white;
    font-size: 16px;
    margin-right: 10px;
  }

  .link-list {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
    cursor: pointer;
  }

  .link-list a {
    line-height: 14px;
    color: #ccc;
    margin-bottom: 18px;
  }

  .site-list {
    display: flex;
    flex-wrap: wrap;

  }

  .site-list .w-110 {
    width: 110px;
  }

  .logo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 1440px;
    margin: 0 auto;
    color: #fff;
    padding: 30px;
    border-bottom: 1px solid #404040;
  }

  .logo-wrapper>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 24px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #fff;
  }

  .logo-wrapper .img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  .logo-wrapper .footer-contact .img {
    background: url(../assets/images/footer-contact.png) no-repeat;
    background-size: 100% 100%;
  }

  .logo-wrapper .footer-download .img {
    background: url(../assets/images/footer-download.png) no-repeat;
    background-size: 100% 100%;
  }

  .logo-wrapper .footer-experience .img {
    background: url(../assets/images/footer-experience.png) no-repeat;
    background-size: 100% 100%;
  }

  .logo-wrapper .footer-price .img {
    background: url(../assets/images/footer-price.png) no-repeat;
    background-size: 100% 100%;
  }

  .footer-copyright {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 20px 0;
  }

  .footer-copyright .gov {
    width: 20px;
    height: 20px;
    background: url(../assets/images/icon-gov.png) no-repeat 50%;
    margin-right: 0;
  }

  .footer-copyright p {
    margin-left: 10px;
    font-size: 14px;
    color: #8c8c8c;
  }

  .footer-copyright p a {
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
  }

  .divider-color {
    margin: 10px 0;
    height: 0px !important;
    opacity: 0.52;
    border: 1px solid #5b5b5b;
    display: block;
    width: 100%;
  }
}

.page-right-nav {
  position: fixed;
  bottom: 80px;
  right: 30px;
  width: 40px;
  height: auto;
  z-index: 9999;

  .el-button {
    margin: 10px 0 0;
    padding: 0;
    width: 40px;
    height: 40px;
  }

  .popover-img {
    display: flex;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    color: black;
    justify-content: center;
    margin-left: 0px;
  }

  .share {
    width: 50px;
    height: 55px;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>