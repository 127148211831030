<template>
  <div class="app-wrapper">
    <header class="page-header-menu">
      <div class="container">
        <div class="menu-lt-logo">
          <!-- <a href="/"><img src="../assets/images/logo_12.png" /></a> -->
          <a href="/"><img src="../assets/images/logo_12.png" /></a>
        </div>
        <div class="menu-fl-login">
          <a href="https://eic.yldatacloud.com" class="link">登录</a>
          <span class="line">|</span>
          <a href="https://eic.yldatacloud.com" class="link">注册</a>
        </div>
        <el-menu class="el-menu-demo menu-nav" mode="horizontal" text-color="#ffffffcc" active-text-color="#ffffff"
          background-color="#2c5474">
          <template class="container">
            <el-submenu index="1" :show-timeout="0" :hide-timeout="0">
              <template slot="title">产品版本</template>
              <el-menu-item index="1-1" @click="gotopublicownership">公有云版</el-menu-item>
              <el-menu-item index="1-2" @click="gotoprivatizeView">私有化部署版</el-menu-item>
            </el-submenu>
            <!-- <el-menu-item index="2" @click="gotoprice">价格</el-menu-item> -->
            <el-submenu index="3" :show-timeout="0" :hide-timeout="0">
              <template slot="title">关于我们</template>
              <el-menu-item index="3-1" @click="gotoabout('0')">公司介绍</el-menu-item>
              <el-menu-item index="3-2" @click="gotoabout('1')">项目案例</el-menu-item>
              <el-menu-item index="3-3" @click="gotoabout('2')">新闻动态</el-menu-item>
              <el-menu-item index="3-4" @click="gotoabout('3')">联系我们</el-menu-item>
            </el-submenu>
            <el-submenu index="4" :show-timeout="0" :hide-timeout="0">
              <template slot="title">技术支持</template>
              <el-menu-item index="4-1" @click="gotoabout('5')">产品教程</el-menu-item>
              <el-menu-item index="4-2" @click="gotoabout('6')">版本更新</el-menu-item>
              <el-menu-item index="4-3" @click="gotoabout('7')">常见问题</el-menu-item>
              <el-menu-item index="4-4" @click="gotoabout('8')">问题反馈</el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </header>
    <main>
      <slot></slot>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";

export default {
  name: "Layout",
  components: {
    Footer,
  },
  computed: {
    ...mapGetters("user", ["userName"]),
  },
  filters: {
    // 导航菜单样式
    navClass(key, route) {
      const {
        name,
        meta: { activeNav },
      } = route;
      return {
        active: name === key || activeNav === key,
      };
    },
  },
  methods: {
    // 用户头像下拉菜单
    onUserMenu(command) { },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    gotoprivatizeView() {
      // this.$router.push('/privatizeview')
      if (this.$route.path !== '/privatizeview') {
        this.$router.push('/privatizeview');
      }
    },
    gotopublicownership() {
      // this.$router.push('/publicownership')
      if (this.$route.path !== '/publicownership') {
        this.$router.push('/publicownership');
      }
    },
    gotoprice() {
      // this.$router.push('/price')
      if (this.$route.path !== '/price') {
        this.$router.push('/price');
      }
    },
    gotoabout(index) {
      if (this.$route.path !== '/about') {
        this.$router.push({ path: '/about', query: { index: index } });
      } else {
        this.$store.commit('updateIndex', index);
      }
    },
  },
};
</script>
<style lang="scss">
@import "../assets/style/main.scss";

.page-head-seize {
  height: 60px;
  width: 100%;
}

.page-header-menu {
  position: fixed;
  width: 100%;
  z-index: 99;
  // background-color: #262626;
  background-color: #2c5474;

  font-size: 16px;

  .el-menu-item,
  .el-submenu__title {
    font-size: 16px;
  }

  .el-menu.el-menu--horizontal {
    border: none;
  }

  .menu-nav {
    margin-right: 100px;
  }

  .menu-lt-logo {
    width: 100px;
    height: 50px;
    padding-top: 10px;
    float: left;
    position: relative;
    z-index: 2;
    overflow: hidden;

    img {
      height: 80%;
    }
  }

  .menu-fl-login {
    float: right;
    color: #fff;
    position: relative;
    z-index: 2;
    padding: 18px;

    .line {
      padding: 0 10px;
      display: inline-block;
      vertical-align: middle;
    }

    .link {
      color: #fff;
      display: inline-block;
      vertical-align: middle;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>