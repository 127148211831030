import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index: 0 // 初始值
  },
  getters: {

  },
  mutations: {
    updateIndex(state, newIndex) {
      state.index = newIndex;
    }
  },
  actions: {
    triggerApply(context) {
      // 在这里执行触发 apply 的操作
      // 例如触发 apply 后的其他操作
      // 可以在这里调用 PrivatizeView.vue 的 goApply 方法
      privatizeView.goApply();
    }
  },
  modules: {
    
  }
})
